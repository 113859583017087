import React, { useEffect, useRef, useState } from 'react';
import { arrayOf, func, node, number, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../Field';
import BlockBuilder from '../../BlockBuilder';

import SectionContainer from '../SectionContainer';
import css from './SectionCategories.module.css';

import * as branding from '../../../../config/configBranding';

const KEY_CODE_ARROW_LEFT = 37;
const KEY_CODE_ARROW_RIGHT = 39;

// The number of columns (numColumns) affects styling and responsive images
const COLUMN_CONFIG = [
  { css: css.oneColumn, responsiveImageSizes: '(max-width: 767px) 100vw, 1200px' },
  { css: css.twoColumns, responsiveImageSizes: '(max-width: 767px) 100vw, 600px' },
  { css: css.threeColumns, responsiveImageSizes: '(max-width: 767px) 100vw, 400px' },
  { css: css.fourColumns, responsiveImageSizes: '(max-width: 767px) 100vw, 290px' },
];
const getIndex = numColumns => numColumns - 1;
const getColumnCSS = numColumns => {
  const config = COLUMN_CONFIG[getIndex(numColumns)];
  return config ? config.css : COLUMN_CONFIG[0].css;
};
const getResponsiveImageSizes = numColumns => {
  const config = COLUMN_CONFIG[getIndex(numColumns)];
  return config ? config.responsiveImageSizes : COLUMN_CONFIG[0].responsiveImageSizes;
};

// Section component that's able to show blocks in a carousel
// the number blocks visible is defined by "numColumns" prop.
const SectionCategories = props => {
  const {
    sectionId,
    className,
    rootClassName,
    defaultClasses,
    numColumns,
    title,
    description,
    appearance,
    callToAction,
    blocks,
    options,
  } = props;
  const numberOfBlocks = blocks?.length;
  const hasBlocks = numberOfBlocks > 0;

  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };

  const [isIntersecting, setIsIntersecting] = useState(false);
  const [hasAnimated, setHasAnimated] = useState(false);

  const [isIntersectingOurStory, setIsIntersectingOurStory] = useState(false);
  const [hasAnimatedOurStory, setHasAnimatedOurStory] = useState(false);

  const [isIntersectingWhyItMatters, setIsIntersectingWhyItMatters] = useState(false);
  const [hasAnimatedWhyItMatters, setHasAnimatedWhyItMatters] = useState(false);

  const [isIntersectingStartEarning, setIsIntersectingStartEarning] = useState(false);
  const [hasAnimatedStartEarning, setHasAnimatedStartEarning] = useState(false);

  const [isIntersectingFindRent, setIsIntersectingFindRent] = useState(false);
  const [hasAnimatedFindRent, setHasAnimatedFindRent] = useState(false);

  const [isIntersectingExploreOurFeatures, setIsIntersectingExploreOurFeatures] = useState(false);
  const [hasAnimatedExploreOurFeatures, setHasAnimatedExploreOurFeatures] = useState(false);

  const categoryRef = useRef(null);
  const ourStoryRef = useRef(null);
  const whyItMattersRef = useRef(null);
  const startEarningRef = useRef(null);
  const findRentRef = useRef(null);
  const exploreOurFeaturesRef = useRef(null);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleWindowSizeChange = () => {
      setIsMobile(window.innerWidth <= 768);
    }

    handleWindowSizeChange();

    window.addEventListener('resize', handleWindowSizeChange);

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsIntersecting(true);
          setHasAnimated(true);
        } else {
          setIsIntersecting(false);
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.3,
      }
    );

    const ourStoryObserver = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsIntersectingOurStory(true);
          setHasAnimatedOurStory(true);
        } else {
          setIsIntersectingOurStory(false);
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.8,
      }
    );

    const whyItMattersObserver = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsIntersectingWhyItMatters(true);
          setHasAnimatedWhyItMatters(true);
        } else {
          setIsIntersectingWhyItMatters(false);
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.8,
      }
    );

    const startEarningObserver = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsIntersectingStartEarning(true);
          setHasAnimatedStartEarning(true);
        } else {
          setIsIntersectingStartEarning(false);
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.5,
      }
    );

    const findRentObserver = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsIntersectingFindRent(true);
          setHasAnimatedFindRent(true);
        } else {
          setIsIntersectingFindRent(false);
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.5,
      }
    );

    const exploreOurFeaturesObserver = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsIntersectingExploreOurFeatures(true);
          setHasAnimatedExploreOurFeatures(true);
        } else {
          setIsIntersectingExploreOurFeatures(false);
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.5,
      }
    );

    if (categoryRef.current) {
      observer.observe(categoryRef.current);
    }

    if (ourStoryRef.current) {
      ourStoryObserver.observe(ourStoryRef.current);
    }

    if (whyItMattersRef.current) {
      whyItMattersObserver.observe(whyItMattersRef.current);
    }

    if (startEarningRef.current) {
      startEarningObserver.observe(startEarningRef.current);
    }

    if (findRentRef.current) {
      findRentObserver.observe(findRentRef.current);
    }

    if (exploreOurFeaturesRef.current) {
      exploreOurFeaturesObserver.observe(exploreOurFeaturesRef.current);
    }

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
      observer.disconnect();
      ourStoryObserver.disconnect();
      whyItMattersObserver.disconnect();
      startEarningObserver.disconnect();
      findRentObserver.disconnect();
      exploreOurFeaturesObserver.disconnect();
    };
  }, []);



  return (
    <SectionContainer
      id={sectionId}
      className={className}
      rootClassName={rootClassName}
      appearance={appearance}
      options={fieldOptions}
    >
      {hasBlocks ? (
        <>
          <CategoriesSection categoryRef={categoryRef} isIntersecting={isIntersecting} hasAnimated={hasAnimated} />
          <OurStorySection ourStoryRef={ourStoryRef} isIntersectingOurStory={isIntersectingOurStory} hasAnimatedOurStory={hasAnimatedOurStory} />
          <WhyItMattersSection whyItMattersRef={whyItMattersRef} hasAnimatedWhyItMatters={hasAnimatedWhyItMatters} />
          <StartEarningSection startEarningRef={startEarningRef} isMobile={isMobile} hasAnimatedStartEarning={hasAnimatedStartEarning} />
          <FindAndRentSection findRentRef={findRentRef} isMobile={isMobile} hasAnimatedFindRent={hasAnimatedFindRent} />
          <ExploreOurFeaturesSection exploreOurFeaturesRef={exploreOurFeaturesRef} isMobile={isMobile} hasAnimatedExploreOurFeatures={hasAnimatedExploreOurFeatures} />
        </>
      ) : null}
    </SectionContainer>
  );
};

const CategoriesSection = ({ categoryRef, isIntersecting, hasAnimated }) => {
  return (
    <div ref={categoryRef} className={classNames(css.categoryBody, css.backgroundWhite)}>
      <div className={css.categoryTitle}>Find what you need</div>
      <div className={css.categorySubTitle}>Rent anything from people in your area today</div>
      <div>
        <div className={css.categoryGrid}>
          <div className={classNames(css.electronics, hasAnimated && css.electronicsVisible)}>
            <a href='s?pub_categoryLevel1=electronics'>
              <img src={branding.electronicsChildImageURL} alt="Electronics" ></img>
              <div className={css.categoryName}>Electronics</div>
            </a>
          </div>
          <div className={classNames(css.slideInLeft, hasAnimated && css.slideInLeftVisible)}>
            <div className={css.categoryItem}>
              <a href='s?pub_categoryLevel1=homeware'>
                <img src={branding.homewareChildImageURL} alt="Homeware"></img>
                <div className={css.categoryName}>Homeware</div>
              </a>
            </div>
          </div>
          <div className={classNames(css.slideInRight, hasAnimated && css.slideInRightVisible)}>
            <div className={css.categoryItem}>
              <a href='s?pub_categoryLevel1=outdoor-equipment'>
                <img src={branding.outdoorEquipmentChildImageURL} alt="Outdoor Equipment"></img>
                <div className={css.categoryName}>Outdoor Equipment</div>
              </a>
            </div>
          </div>
          <div className={classNames(css.slideInRight, hasAnimated && css.slideInRightVisible)}>
            <div className={css.categoryItem}>
              <a href='s?pub_categoryLevel1=sports-and-leisure'>
                <img src={branding.sportsChildImageURL} alt="Sports & Leisure"></img>
                <div className={css.categoryName}>Sports & Leisure</div>
              </a>
            </div>
          </div>
          <div className={classNames(css.slideInLeft, hasAnimated && css.slideInLeftVisible)}>
            <div className={css.categoryItem}>
              <a href='s?pub_categoryLevel1=party'>
                <img src={branding.partyImageURL} alt="Party"></img>
                <div className={css.categoryName}>Party</div>
              </a>
            </div>
          </div>
          <div className={classNames(css.slideInRight, hasAnimated && css.slideInRightVisible)}>
            <div className={css.categoryItem}>
              <a href='s?pub_categoryLevel1=child-and-infant-gear'>
                <img src={branding.categoryChildImageURL} alt="Child & Infant Gear"></img>
                <div className={css.categoryName}>Child & Infant Gear</div>
              </a>
            </div>
          </div>
          <div className={classNames(css.slideInRight, hasAnimated && css.slideInRightVisible)}>
            <div className={css.categoryItem}>
              <a href='s?pub_categoryLevel1=clothing'>
                <img src={branding.clothingChildImageURL} alt="Clothing"></img>
                <div className={css.categoryName}>Clothing</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const OurStorySection = ({ ourStoryRef, isIntersectingOurStory, hasAnimatedOurStory }) => {
  return (
    <div className={css.storyBody}>
      <div className={css.storyContainer}>
        <div className={css.storyButton}>Our Story</div>
        <div ref={ourStoryRef}>
          <div className={classNames(css.storyFadeInAnimation, hasAnimatedOurStory && css.storyFadeInAnimationVisible)}>
          <div className={css.storyText}>
            <p>It all started with a simple idea: what if we could do more with less? By borrowing and sharing, we can reduce waste and make better use of what we have. That’s how <span className={css.highlight}>Lendit</span> was born.</p>
            <p>We're here to change how we consume, making choices that are kinder to the planet. Whether you're passionate about sustainability or just looking to save, <span className={css.highlight}>Lendit</span> is here to help us all make a difference.</p>
          </div>
          <div className={css.learnMore}>
            <div className={css.circle} ></div>
            <a href='p/about'><span className={css.learnText}>Learn More</span></a>
          </div>
        </div>
        </div>
      </div>
    </div>
  );
}

const WhyItMattersSection = ({ whyItMattersRef, hasAnimatedWhyItMatters }) => {
  return (
    <div ref={whyItMattersRef}>
      <div className={css.mattersBody}>
        <div className={css.whyItMattersContainer}>
          <div className={css.storyButton}>Why It Matters</div>
          <div>
            <div className={css.featuresGrid}>
              <div className={classNames(
                css.featureCard,
                css.slideInLeft,
                hasAnimatedWhyItMatters && css.slideInLeftVisible
              )}>
                <img src={branding.supportSustainabilityImageURL} alt="Support Sustainability Icon" />
                <h3>Support Sustainability</h3>
              </div>
              {/* Second card - Slide from the left */}
              <div className={classNames(
                css.featureCard,
                css.slideInLeft,
                hasAnimatedWhyItMatters && css.slideInLeftVisible
              )}>
                <img src={branding.minimizeWasteImageURL} alt="Minimize Waste Icon" />
                <h3>Minimize Waste</h3>
              </div>

              {/* Third card - Slide from the right */}
              <div className={classNames(
                css.featureCard,
                css.slideInRight,
                hasAnimatedWhyItMatters && css.slideInRightVisible
              )}>
                <img src={branding.buildCommunityImageURL} alt="Build Community Icon" />
                <h3>Build Community</h3>
              </div>

              {/* Fourth card - Slide from the right */}
              <div className={classNames(
                css.featureCard,
                css.slideInRight,
                hasAnimatedWhyItMatters && css.slideInRightVisible
              )}>
                <img src={branding.saveMoreImageURL} alt="Save More Icon" />
                <h3>Save More</h3>
              </div>


            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

const StartEarningSection = ({ startEarningRef, isMobile, hasAnimatedStartEarning }) => {
  return (
    <div ref={startEarningRef}>
      <div className={css.startEarningContainer}>
        <div className={css.startEarningColumns}>
          <div className={css.imageColumn}>
            <div className={isMobile ? '' : classNames(css.slideInLeft, hasAnimatedStartEarning && css.slideInLeftVisible)}>
              <img src={branding.startEarningImageURL} alt="Earning Image" />
            </div>
          </div>

          <div className={css.column}>
            <div className={isMobile ? '' : classNames(css.slideInRight, hasAnimatedStartEarning && css.slideInRightVisible)}>
              <div className={css.startEarningHeading}>Start Earning with <span className={css.highlight}>Lendit</span></div>
              <div className={css.step}>
                <div className={css.stepIcon}>1</div>
                <div className={css.stepContent}>
                  <div className={css.stepTitle}>Sign Up</div>
                  <div className={css.stepText}>Signing up and getting verified on Lendit takes a couple of minutes.</div>
                </div>
              </div>

              <div className={css.step}>
                <div className={css.stepIcon}>2</div>
                <div className={css.stepContent}>
                  <div className={css.stepTitle}>List your items</div>
                  <div className={css.stepText}>Take a picture of idle items lying around, set your price and terms. It's free to list!</div>
                </div>
              </div>

              <div className={css.step}>
                <div className={css.stepIcon}>3</div>
                <div className={css.stepContent}>
                  <div className={css.stepTitle}>Accept offers</div>
                  <div className={css.stepText}>Review and approve rental requests from renters.</div>
                </div>
              </div>

              <div className={css.step}>
                <div className={css.stepIcon}>4</div>
                <div className={css.stepContent}>
                  <div className={css.stepTitle}>Get Paid!</div>
                  <div className={css.stepText}>Receive payments once the rental is complete.</div>
                </div>
              </div>

              <div className={css.learnMore}>
                <div className={css.circle} ></div>
                <a href='p/how-it-works'><span className={css.learnText}>Learn More About How it Works</span></a>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const FindAndRentSection = ({ findRentRef, isMobile, hasAnimatedFindRent }) => {
  return (
    <div ref={findRentRef}>
      <div className={css.findAndRentContainer}>

        <div className={css.startEarningColumns}>
          <div className={isMobile ? '' : classNames(css.slideInLeft, hasAnimatedFindRent && css.slideInLeftVisible)}>
            <div className={css.findAndRentColumn}>
              <div className={css.startEarningHeading}>Find and Rent with <span className={css.highlight}>Lendit</span></div>
              <div className={css.step}>
                <div className={css.stepIcon}>1</div>
                <div className={css.stepContent}>
                  <div className={css.stepTitle}>Sign Up</div>
                  <div className={css.stepText}>Signing up and getting verified on Lendit takes a couple of minutes.</div>
                </div>
              </div>

              <div className={css.step}>
                <div className={css.stepIcon}>2</div>
                <div className={css.stepContent}>
                  <div className={css.stepTitle}>Find an item nearby</div>
                  <div className={css.stepText}>Search for the items you’re looking for and filter by type.</div>
                </div>
              </div>

              <div className={css.step}>
                <div className={css.stepIcon}>3</div>
                <div className={css.stepContent}>
                  <div className={css.stepTitle}>Request and book</div>
                  <div className={css.stepText}>Send a request and secure your booking with just a few clicks.</div>
                </div>
              </div>

              <div className={css.step}>
                <div className={css.stepIcon}>4</div>
                <div className={css.stepContent}>
                  <div className={css.stepTitle}>Collect and enjoy!</div>
                  <div className={css.stepText}>Pick up your item and use it as needed.</div>
                </div>
              </div>

              <div className={css.learnMore}>
                <div className={css.circle} ></div>
                <a href='p/how-it-works?s=lender'><span className={css.learnText}>Learn More About How it Works</span></a>
              </div>

            </div>
          </div>
          <div className={css.findAndRentImageColumn}>
            <div className={isMobile ? '' : classNames(css.slideInRight, hasAnimatedFindRent && css.slideInRightVisible)}>
              <img src={branding.findAndRentImageURL} alt="Earning Image" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ExploreOurFeaturesSection = ({ exploreOurFeaturesRef, isMobile, hasAnimatedExploreOurFeatures }) => {
  return (
    <div ref={exploreOurFeaturesRef}>
      <div className={css.exploreOurFeaturesContainer}>
        <h2 className={css.exploreOurFeaturesHeading}>Explore Our Features</h2>
        <div className={css.exploreOurFeaturesGrid}>
          {/* <div className={isMobile ? '' : classNames(css.slideInLeft, hasAnimatedExploreOurFeatures && css.slideInLeftVisible)}> */}
          <div>
            <div className={css.exploreOurFeatureCard}>
              <img src={branding.securePaymentsImageURL} alt="Secure Payments Image" />
              <div className={css.exploreOurFeatureTitle}>Secure Payments</div>
              <p>Rest assured with our safe and secure payment system powered by Stripe, ensuring your transactions are protected.</p>
            </div>
          </div>
          {/* <div className={isMobile ? '' : classNames(css.slideInRight, hasAnimatedExploreOurFeatures && css.slideInRightVisible)}> */}
          <div>
            <div className={css.exploreOurFeatureCard}>
              <img src={branding.inAppMessagingImageURL} alt="In-App Messaging Image" />
              <div className={css.exploreOurFeatureTitle}>In-App Messaging</div>
              <p>Communicate easily with renters and lenders through our integrated chat feature, ensuring smooth and direct communication.</p>
            </div>
          </div>

          {/* <div className={isMobile ? '' : classNames(css.slideInLeft, hasAnimatedExploreOurFeatures && css.slideInLeftVisible)}> */}
          <div>
            <div className={css.exploreOurFeatureCard}>
              <img src={branding.searchImageURL} alt="Search Image" />
              <h3>Search</h3>
              <p>Quickly find the items you need with our dynamic search feature.</p>
            </div>
          </div>

          {/* <div className={isMobile ? '' : classNames(css.slideInRight, hasAnimatedExploreOurFeatures && css.slideInRightVisible)}> */}
          <div>
            <div className={css.exploreOurFeatureCard}>
              <img src={branding.quickPayoutImageURL} alt="Quick Payout Image" />
              <div className={css.exploreOurFeatureTitle}>Quick Payout with Stripe</div>
              <p>Receive your earnings quickly and securely with Stripe.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionCategories.defaultProps = {
  className: null,
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  numColumns: 1,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  blocks: [],
  options: null,
};

SectionCategories.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  numColumns: number,
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  blocks: arrayOf(object),
  options: propTypeOption,
};

export default SectionCategories;